import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMoment from "vue-moment-v3";
import VueGoogleMaps from "@fawmi/vue-google-maps";

loadFonts();

createApp(App)
  .use(vuetify)
  .use(VueAxios, axios)
  .use(VueMoment)
  .use(VueGoogleMaps, {
    load: {
      libraries: "geometry",
      key: "AIzaSyCs5e12W_LX9w9favcP-K-z5EfrOm5aCGI",
    },
  })
  .mount("#app");
