<template>
  <v-app>
    <v-app-bar color="red-accent-4">
      <v-app-bar-title>
        <v-img src="@/assets/logo.png" max-height="40" max-width="100"></v-img>
      </v-app-bar-title>
      <v-btn icon @click="dialog = true">
        <v-icon>mdi-calendar-clock</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container class="pa-0 h-100 d-flex align-start flex-column" fluid>
        <v-list class="w-100 pa-0" v-if="bus">
          <v-list-item class="pa-3" @click="this.center = this.bus.position">
            <template v-slot:prepend>
              <v-icon>mdi-shield</v-icon>
            </template>
            <v-list-item-title class="font-weight-bold">
              BUS {{ bus.id }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Arrive At
              {{
                $filters.moment(
                  new Date(),
                  "add",
                  duration + " seconds",
                  "h:mm A"
                )
              }}
            </v-list-item-subtitle>
            <template v-slot:append> {{ distance }} KM </template>
          </v-list-item>
        </v-list>
        <v-row no-gutters>
          <GMapMap
            :center="center"
            ref="myMapRef"
            :zoom="15"
            map-type-id="roadmap"
            style="width: 100vw"
            class="h-100"
          >
            <GMapMarker
              v-for="item in this.busStopList"
              v-bind:key="item.text"
              v-bind:position="item.position"
              :label="item.text"
              @click="busStop = item.text"
            />
            <GMapMarker
              v-for="(item, index) in busList"
              v-bind:key="index"
              v-bind:position="item.position"
              :label="{ text: item.id, color: 'white' }"
              :icon="{ url: require('@/assets/marker-bus.png') }"
              @click="bus = item"
            />
            <GMapPolyline
              :path="path"
              v-bind:options="{ strokeColor: '#0067f2' }"
            />
          </GMapMap>
        </v-row>
      </v-container>
    </v-main>

    <v-bottom-navigation v-model="busStop" grow color="red-accent-4">
      <v-btn
        v-for="item in this.busStopList"
        v-bind:key="item.text"
        :value="item.text"
        @click="busStop = item.text"
      >
        {{ item.text }}
      </v-btn>
    </v-bottom-navigation>

    <v-snackbar
      class="mb-15"
      v-model="snackbar"
      v-if="schedule"
      location="bottom"
      timeout="3000"
    >
      Next schedule on {{ $filters.moment([schedule, "HH:mm"], "h:mm A") }}
      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>{{ busStop }} - Schedule</v-card-title>
        <v-divider></v-divider>
        <v-chip-group
          class="d-flex justify-center pe-1"
          v-model="schedule"
          selected-class="bg-red-accent-4"
        >
          <v-chip
            label
            class="ma-1"
            v-for="(item, i) in scheduleList"
            :key="i"
            :value="item"
            style="pointer-events: none"
          >
            {{ $filters.moment([item, "HH:mm"], "hh:mm A") }}
          </v-chip>
        </v-chip-group>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import mqttService from "@/components/mqttService.js";
import busStops from "@/assets/busStop.json";
export default {
  name: "App",
  watch: {
    busStop: function (newVal) {
      this.busList = [];
      this.bus = null;

      var item = this.busStopList.find((item) => {
        return item.text === newVal;
      });
      this.center = item.position;
      this.scheduleList = item.schedule;
      this.schedule = this.getClosestTime(
        this.$filters.moment(new Date(), "HH:mm")
      );
      this.snackbar = true;

      this.$gmapApiPromiseLazy().then((google) => {
        this.path = new google.maps.geometry.encoding.decodePath(
          item.encodedPolyline
        );
      });

      // mqttService.unsubscribe("ucsi/bus/+/gps");
      mqttService.unsubscribe(
        this.busStopList.map(function (item) {
          return `ucsi/bus/${item.text}/gps`;
        })
      );
      mqttService.subscribe(`ucsi/bus/${newVal}/gps`);
    },
    busList: {
      handler(newVal) {
        if (this.bus) {
          var selectedBus = newVal.find((item) => {
            return item.id === this.bus.id;
          });

          if (selectedBus) {
            this.bus.position = selectedBus.position;

            var selectedBusStop = this.busStopList.find((item) => {
              return item.text === this.busStop;
            });

            if (selectedBusStop) {
              this.distance = this.getDistance(
                selectedBus.position,
                selectedBusStop.position
              );
            }
          } else {
            this.bus = newVal[0];
          }
        } else {
          this.bus = newVal[0];
        }
      },
      deep: true,
    },
    bus: function (newVal) {
      if (newVal) {
        this.center = newVal.position;

        var selectedBusStop = this.busStopList.find((item) => {
          return item.text === this.busStop;
        });

        if (selectedBusStop) {
          this.getDuration(
            newVal.position,
            selectedBusStop.position,
            (result) => {
              this.duration = result.duration;
              this.distance = result.distance;
            }
          );
        }
      }
    },
  },
  data() {
    return {
      snackbar: true,
      dialog: false,
      center: { lat: 3.0792675, lng: 101.7331772 },
      busStopList: busStops,
      busList: [],
      busStop: null,
      bus: null,
      path: [],
      scheduleList: [],
      schedule: null,
      duration: null,
      distance: null,
      error: null,
    };
  },
  computed: {},
  created: function () {
    mqttService.message(this.message);
  },
  methods: {
    message(topic, payload) {
      const location = JSON.parse(payload);
      var item = this.busList.find((item) => {
        return item.id === location.id;
      });
      if (item) {
        const index = this.busList.indexOf(item);
        if (index !== -1) {
          this.busList.splice(index, 1);
        }
      }
      this.busList.push(location);
    },
    async getDuration(origin, destination, callback) {
      this.$gmapApiPromiseLazy().then((google) => {
        const service = new google.maps.DistanceMatrixService();
        service
          .getDistanceMatrix({
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
            unitSystem: google.maps.UnitSystem.METRIC,
          })
          .then((data) => {
            const result = {
              duration: data.rows[0].elements[0].duration.value,
              distance: (
                data.rows[0].elements[0].distance.value / 1000
              ).toFixed(2),
            };
            callback(result);
          });

        // const directionsService = new google.maps.DirectionsService();
        // directionsService.route(
        //   {
        //     origin: newVal.position,
        //     destination: selectedBusStop.position,
        //     travelMode: "DRIVING",
        //     unitSystem: google.maps.UnitSystem.METRIC,
        //   },
        //   (data) => {
        //     console.log(data);
        //   }
        // );
      });
    },
    getDistance(origin, destination) {
      var lat1 = origin.lat;
      var lng1 = origin.lng;
      var lat2 = destination.lat;
      var lng2 = destination.lng;

      const radius = 6371; // Earth's radius in km
      const dLat = this.toRadians(lat2 - lat1);
      const dLng = this.toRadians(lng2 - lng1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.toRadians(lat1)) *
          Math.cos(this.toRadians(lat2)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = radius * c;
      const result = distance.toFixed(2);
      return result;
    },
    toRadians(degree) {
      return degree * (Math.PI / 180);
    },
    getClosestTime(targetTime) {
      return (
        this.scheduleList.find((time) => time >= targetTime) ||
        this.scheduleList[0]
      );
    },
  },
  mounted: function () {
    this.busStop = this.busStopList[0].text;
  },
};
</script>
