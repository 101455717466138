import mqtt from "precompiled-mqtt";

// var mqttClient = mqtt.connect("mqtts://localhost:8081");
// var mqttClient = mqtt.connect("mqtts://test.mosquitto.org:8081");
// var mqttClient = mqtt.connect("mqtt://busapps.ucsiuniversity.edu.my:9001");
var mqttClient = mqtt.connect("mqtts://busapps.ucsiuniversity.edu.my:8081");

class mqttService {
  static subscribe(topic) {
    mqttClient.subscribe(topic);
  }
  static unsubscribe(topic) {
    mqttClient.unsubscribe(topic);
  }
  static message(callback) {
    mqttClient.on("message", callback);
  }
}

export default mqttService;
